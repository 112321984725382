<template>
  <div style="height: 100%;overflow-y: hidden;">
    <van-button type="success" @click="searchNearbyBluetoothDevices">搜索蓝牙</van-button>
    <br>
    <br>
    <van-button type="primary" @click="connectToBluetoothDevice">连接测试</van-button>
    <br>
    <br>
    <van-button v-if="false" type="warning" @click="sendData">发送数据</van-button>
    <br>
    <br>
    <div
      style="margin-top: 20px;border:1px solid green;padding:10px;height:450px;overflow-y: scroll;border-radius: 5px;">
      {{ bluetoothData }}
    </div>
  </div>
</template>

<script setup>
import { closeToast, showConfirmDialog, showFailToast, showLoadingToast, showToast } from 'vant';
import { onMounted, ref } from 'vue';

//蓝牙服务
const bluetoothServer = ref()
//服务注册特性
const bluetoothCharacteristic = ref()
//服务注册--写入
const bluetoothCharacteristicWrite = ref()
//蓝牙设备传送的数据
const bluetoothData = ref('')
//服务UUID
const serviceUuid = ref('6e400001-b5a3-f393-e0a9-e50e24dcca9e')

const init = () => {
  if (!navigator.bluetooth) {
    console.log("Web Bluetooth API is not available in this browser.");
    showToast("不支持蓝牙服务")
  } else {
    showConfirmDialog({
      title: "温馨提示",
      message: '支持蓝牙设备，是否连接设备',
    }).then(() => {
      connectToBluetoothDevice()
    }).catch(() => {

    })
  }
}

//发送数据
async function sendData() {
  getCharacteristicWrite()
  if (!bluetoothCharacteristicWrite.value) return false
  try {
    //发送的数据
    let data = "HT"
    const encoder = new TextEncoder();  // 用于将字符串转换为 ArrayBuffer
    const buffer = encoder.encode(data);  // 将数据编码为 ArrayBuffer

    await bluetoothCharacteristic.value.writeValue(buffer);
    console.log("Data sent:", data);
  } catch (error) {
    console.error("Failed to send data:", error);
  }
}

//搜索附近的蓝牙设备
async function searchNearbyBluetoothDevices() {
  try {
    const devices = await navigator.bluetooth.requestDevice({
      acceptAllDevices: true, // 设置为 true 可以发现所有蓝牙设备
      // optionalServices: ['battery_service', 'device_information'] // 可以指定特定的服务UUID，或者只查找特定设备
    });

    const server = await devices.gatt.connect();
    getCharacteristic()
    showLoadingToast({
      message: '连接中...',
      forbidClick: true,
      duration: 0,
    });
    server.value = server
    return server;
  } catch (error) {
    console.error("Error during Bluetooth scan:", error);
  }
}

// 获取服务与特征--读取数据
async function getCharacteristic() {
  if (!bluetoothServer.value) return false
  try {
    const service = await bluetoothServer.value.getPrimaryService(serviceUuid.value);
    const characteristic = await service.getCharacteristic('6E400003-B5A3-F393-E0A9-E50E24DCCA9E'.toLowerCase());
    bluetoothCharacteristic.value = characteristic
    //监听数据
    receiveData()
    return characteristic;
  } catch (error) {
    console.error("Failed to get characteristic:", error);
  }
}
// 获取服务与特征--写入数据
async function getCharacteristicWrite() {
  if (!bluetoothServer.value) return false
  try {
    const service = await bluetoothServer.value.getPrimaryService(serviceUuid.value);
    const characteristic = await service.getCharacteristic('6E400002-B5A3-F393-E0A9-E50E24DCCA9E'.toLowerCase());
    bluetoothCharacteristicWrite.value = characteristic
    bluetoothCharacteristicWrite.value.addEventListener('characteristicvaluechanged', (event) => {
    const value = event.target.value;
    const decoder = new TextDecoder();  // 用于将 ArrayBuffer 转换为字符串
    bluetoothData.value += decoder.decode(value) + "  ;  "
    console.log("Received data:", decoder.decode(value));
  });

  bluetoothCharacteristicWrite.value.startNotifications();  // 启用通知
  } catch (error) {
    console.error("Failed to get characteristic:", error);
  }
}

//监听数据
function receiveData() {
  console.log('------characteristic-------', bluetoothCharacteristic.value);
  if (!bluetoothCharacteristic.value) return false
  bluetoothCharacteristic.value.addEventListener('characteristicvaluechanged', (event) => {
    const value = event.target.value;
    const decoder = new TextDecoder();  // 用于将 ArrayBuffer 转换为字符串
    bluetoothData.value += decoder.decode(value) + "  ;  "
    console.log("Received data:", decoder.decode(value));
  });

  bluetoothCharacteristic.value.startNotifications();  // 启用通知
  console.log('-------------', 'success');
  closeToast()
  showToast("连接成功")
}

//设备连接
async function connectToBluetoothDevice() {
  showLoadingToast({
    message: '连接中...',
    forbidClick: true,
    duration: 0,
  });
  try {
    // 请求设备
    const device = await navigator.bluetooth.requestDevice({
      filters: [{ name: 'Ashining' }],
      optionalServices: [serviceUuid.value]
    });

    // 监听设备断开连接事件
    device.addEventListener('gattserverdisconnected', onDisconnected);

    // 尝试连接设备
    const server = await device.gatt.connect();
    bluetoothServer.value = server

    // 检查是否成功连接
    if (device.gatt.connected) {
      console.log("Device connected:", device.name);
      //获取服务
      getCharacteristic()
    } else {
      console.log("Device connection failed.");
      showToast("设备连接失败")
    }

    return server;
  } catch (error) {
    console.error("Failed to connect to device:", error);
  }
}

// 断开连接事件处理
function onDisconnected(event) {
  const device = event.target;
  showFailToast("设备已断开连接")
  console.log(`Device ${device.name} disconnected`);
}

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped></style>